/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  function headerAni() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 90) {
        $("main").addClass("darken");
        $(".brand > svg").addClass("darken");
      } else {
        $("main").removeClass("darken");
        $(".brand > svg").removeClass("darken");
      }
    });
  }

  function burger() {
    $(".nav-icon").click(function () {
      $(this).toggleClass("open");
    });
  }

  $.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        $('#myModal').modal('show');
        burger();
        $(".current-menu-item a").click(function () {
          console.log("klick");
          $("button.nav-icon").removeClass("open");
          $(this).closest("div").collapse("hide");
        });
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        $(window).on("load resize scroll", function () {
          $(".section-layout").each(function () {
            if ($(this).isInViewport()) {
              $(this).addClass("inViewport");
              // do something
            } else {
              $(this).removeClass("inViewport");
              // do something else
            }
          });
        });

        $(window).on("scroll", function () {
          const top = $(window).scrollTop();
          if (top > 0) {
            $(".trees").addClass("animateId");
            $(".intro-text").animate({ opacity: 1 });
          } else {
            $(".trees").removeClass("animateId");
          }
        });
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    page: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        headerAni();
        $(window).on("load resize scroll", function () {
          $(".flex-layout").each(function () {
            if ($(this).isInViewport()) {
              $(this).addClass("inViewport");
              // do something
            } else {
              $(this).removeClass("inViewport");
              // do something else
            }
          });
        });
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    single_news: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        $(window).on("load resize scroll", function () {
          $(".content-layout").each(function () {
            if ($(this).isInViewport()) {
              $(this).addClass("inViewport");
              // do something
            } else {
              $(this).removeClass("inViewport");
              // do something else
            }
          });
        });
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    photo_contest: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        setTimeout(function () {
          console.log('reg form');
          $('button#simple-tab-1').trigger("click");
        }, 1000);
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    archive: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
      finalize: function () {
        $(window).on("load resize scroll", function () {
          $(".section-layout").each(function () {
            if ($(this).isInViewport()) {
              $(this).addClass("inViewport");
              // do something
            } else {
              $(this).removeClass("inViewport");
              // do something else
            }
          });
        });
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
